import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";

import { Icon } from '@types';

const Logo: Icon = ({ fill = "white" }) => {
  return (
    <LogoContainer>
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="135 240 625 100" xmlSpace="preserve" width="190" height="35">
      <style type="text/css">
        {`
        .st0{fill:url(#SVGID_1_);}
        .st1{fill:#FFFFFF;}
        .st2{fill:url(#SVGID_2_);}
        .st3{fill:none;stroke:#FFFFFF;stroke-width:3;stroke-miterlimit:10;}
        .st4{fill:none;stroke:#FFFFFF;stroke-width:5.7352;stroke-miterlimit:10;}
        .neuron{fill:${fill};}
        .hub{fill:#808285;}
        `}
      </style>
      <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="138.42" y1="303.9" x2="242.58" y2="303.9" gradientTransform="matrix(1 0 0 -1 0 598)">
        <stop  offset="0" style={{stopColor:"#AD2B5A"}}/>
        <stop  offset="1" style={{stopColor:"#F26522", stopOpacity:0.62}}/>
      </linearGradient>
      <circle className="st0" cx="190.5" cy="294.1" r="52.1"/>
      <polygon className="st1" points="208.9,303.9 190.4,314.1 172.3,303.2 172.7,282.1 191.1,271.9 209.2,282.7 "/>
      <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="178.4949" y1="305.2098" x2="208.1712" y2="305.2098" gradientTransform="matrix(1.0355 5.975858e-04 -5.975858e-04 -1 -9.434 597.8343)">
        <stop  offset="0" style={{stopColor:"#AD2B5A"}}/>
        <stop  offset="1" style={{stopColor:"#F26522", stopOpacity:0.62}}/>
      </linearGradient>
      <polygon className="st2" points="205.7,301.5 190.3,309.7 175.2,301 175.5,284 190.9,275.8 205.9,284.5 "/>
      <polygon className="st1" points="165.7,277.5 161.1,280.1 156.6,277.5 156.6,272.2 161.1,269.5 165.7,272.2 "/>
      <line className="st3" x1="174.3" y1="282.9" x2="165.3" y2="277.2"/>
      <line className="st3" x1="173.5" y1="302.3" x2="164.3" y2="307.7"/>
      <polygon className="st1" points="164.7,312.7 160.1,315.3 155.5,312.7 155.5,307.4 160.1,304.8 164.7,307.4 "/>
      <line className="st3" x1="191" y1="273.4" x2="191" y2="262.7"/>
      <polygon className="st1" points="195.5,261 191,263.5 186.4,261 186.4,255.7 191,253.1 195.5,255.7 "/>
      <polygon className="st1" points="226.3,279.1 221.7,281.7 217.1,279.1 217.1,273.9 221.7,271.2 226.3,273.9 "/>
      <line className="st3" x1="216.6" y1="308.5" x2="207.6" y2="302.8"/>
      <line className="st3" x1="217.6" y1="278.5" x2="208.1" y2="283.2"/>
      <polygon className="st1" points="225.3,313.8 220.7,316.4 216.2,313.8 216.2,308.5 220.7,305.9 225.3,308.5 "/>
      <line className="st4" x1="190.6" y1="336.7" x2="190.6" y2="313.1"/>
      <g>
        <path className="neuron" d="M314.8,318.7v-30.6c0-3.9-0.7-6.5-2-7.9s-3.5-2.1-6.7-2.1c-6.9,0-10.4,3.9-10.4,11.6v29h-13.4v-50.6H295v7.4
          c1.6-2.9,3.7-5.1,6.3-6.5c2.6-1.5,6-2.2,10.1-2.2c2.4,0,4.6,0.4,6.7,1.1s3.8,1.8,5.3,3.2c1.5,1.4,2.6,3.2,3.5,5.3
          c0.9,2.1,1.3,4.5,1.3,7.2v35h-13.4V318.7z"/>
        <path className="neuron" d="M346.3,297c0.2,3.8,1.3,6.9,3.3,9.1c2,2.3,4.7,3.4,8,3.4c2.2,0,4.1-0.5,5.8-1.5c1.7-1,2.8-2.4,3.2-4.2h13.8
          c-1.6,5.2-4.3,9.2-8.2,12c-3.9,2.8-8.6,4.2-14,4.2c-16.9,0-25.3-9.2-25.3-27.5c0-3.9,0.5-7.4,1.6-10.6c1.1-3.1,2.7-5.9,4.8-8.1
          c2.1-2.3,4.6-4,7.7-5.2c3-1.2,6.5-1.8,10.4-1.8c7.8,0,13.7,2.5,17.7,7.5s6,12.5,6,22.6h-34.8V297z M367.4,288.5
          c-0.1-1.8-0.4-3.5-1-4.9s-1.4-2.6-2.3-3.5c-1-0.9-2.1-1.6-3.3-2.1c-1.2-0.4-2.5-0.7-3.8-0.7c-2.7,0-5,1-6.9,2.9s-3.1,4.7-3.3,8.2
          h20.6V288.5z"/>
        <path className="neuron" d="M418.6,318.7v-7.1c-3.4,5.6-8.7,8.4-15.7,8.4c-2.5,0-4.8-0.4-6.9-1.3s-3.9-2.2-5.4-3.8c-1.5-1.6-2.7-3.6-3.5-5.9
          c-0.9-2.3-1.3-4.8-1.3-7.6v-33.3h13.4v31.2c0,6.3,2.8,9.5,8.5,9.5c3.4,0,6-1.1,7.8-3.2c1.7-2.2,2.6-4.9,2.6-8.3v-29.2h13.3v50.6
          L418.6,318.7L418.6,318.7z"/>
        <path className="neuron" d="M437.1,318.7v-50.6h12.5v6.1c1.1-1.7,2.2-3,3.4-4c1.2-1,2.4-1.7,3.7-2.2c1.3-0.5,2.6-0.8,3.9-1c1.3-0.1,2.6-0.2,4-0.2h1.7
          v13.7c-1.2-0.2-2.5-0.3-3.7-0.3c-8.2,0-12.2,4.1-12.2,12.2v26.3H437.1z"/>
        <path className="neuron" d="M491.9,320.1c-4.1,0-7.8-0.7-11-2s-5.9-3.1-8.2-5.4c-2.2-2.3-3.9-5.1-5.1-8.4s-1.7-6.9-1.7-10.8c0-4,0.6-7.6,1.8-10.9
          c1.2-3.3,2.9-6.1,5.2-8.4c2.3-2.3,5-4.1,8.2-5.4s6.8-1.9,10.8-1.9s7.5,0.6,10.7,1.9c3.1,1.3,5.8,3,8.1,5.3c2.2,2.3,3.9,5.1,5.1,8.4
          s1.8,7,1.8,11s-0.6,7.7-1.8,10.9c-1.2,3.3-2.9,6-5.1,8.4c-2.2,2.3-4.9,4.1-8.1,5.4C499.3,319.4,495.8,320.1,491.9,320.1z
          M491.9,308.9c8.2,0,12.2-5.1,12.2-15.4c0-10.2-4.1-15.3-12.2-15.3c-8.2,0-12.2,5.1-12.2,15.3C479.7,303.7,483.7,308.9,491.9,308.9
          z"/>
        <path className="neuron" d="M554.8,318.7v-30.6c0-3.9-0.7-6.5-2-7.9s-3.5-2.1-6.7-2.1c-6.9,0-10.4,3.9-10.4,11.6v29h-13.4v-50.6H535v7.4
          c1.6-2.9,3.7-5.1,6.3-6.5c2.6-1.5,6-2.2,10.1-2.2c2.4,0,4.6,0.4,6.7,1.1s3.8,1.8,5.3,3.2c1.5,1.4,2.6,3.2,3.5,5.3
          c0.9,2.1,1.3,4.5,1.3,7.2v35h-13.4V318.7z"/>
        <path className="hub" d="M606.4,318.7v-31.3c0-3.8-0.7-6.5-2-7.9s-3.5-2.1-6.6-2.1c-1.6,0-3.1,0.3-4.4,1c-1.3,0.7-2.4,1.5-3.3,2.7
          c-0.9,1.1-1.6,2.4-2,3.9s-0.7,3.1-0.7,4.8v29H574v-69h13.4v25.1c0.5-1.1,1.2-2.1,2.2-3.1s2.1-1.8,3.4-2.5c1.3-0.7,2.7-1.2,4.3-1.6
          s3.2-0.6,4.9-0.6c5.2,0,9.4,1.4,12.7,4.3c3.3,2.8,4.9,6.8,4.9,11.9v35.8h-13.4V318.7z"/>
        <path className="hub" d="M658.6,318.7v-7.1c-3.4,5.6-8.7,8.4-15.7,8.4c-2.5,0-4.8-0.4-6.9-1.3c-2.1-0.9-3.9-2.2-5.4-3.8
          c-1.5-1.6-2.7-3.6-3.5-5.9c-0.9-2.3-1.3-4.8-1.3-7.6v-33.3h13.4v31.2c0,6.3,2.8,9.5,8.5,9.5c3.4,0,6-1.1,7.8-3.2
          c1.7-2.2,2.6-4.9,2.6-8.3v-29.2h13.3v50.6L658.6,318.7L658.6,318.7z"/>
        <path className="hub" d="M677.1,318.7v-69h13.2v25.1c3.8-5.3,8.8-7.9,15-7.9c3.3,0,6.2,0.6,8.8,1.9s4.7,3.1,6.5,5.4s3.1,5.1,4.1,8.3
          c0.9,3.2,1.4,6.7,1.4,10.5c0,4.2-0.5,8-1.6,11.4s-2.6,6.2-4.5,8.5s-4.2,4.1-6.9,5.3c-2.7,1.2-5.6,1.8-8.8,1.8
          c-6.3,0-11-2.5-14.1-7.5l-0.1,6.2L677.1,318.7L677.1,318.7z M701.1,309.2c3.4,0,6.1-1.4,8.2-4.3c2.1-2.8,3.2-6.5,3.2-11
          c0-10.4-3.8-15.6-11.5-15.6c-7.5,0-11.2,5.5-11.2,16.4c0,4.3,1.1,7.8,3.2,10.5C695.2,307.9,697.9,309.2,701.1,309.2z"/>
      </g>
      <rect x="511" y="406" className="st1" width="39" height="15"/>
      </svg>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  
  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }
    
    .Logo__Mobile{
      display: block;
    }
  `}
`;
